<template>
  <div>
    <h1 class="headline--md text-bold text-uppercase">User Transfer Transactions</h1>

    <div class="box box--sm box--gray radius-bottom-0 mt-16 d-flex">
      <selection-box
        v-model="params.status"
        :options="userTransferOptions"
        :is-align-right="true"
        :on-check="onGetSwapTokos"
        selected-box-class="is-lg search-box__filter"
      />

      <div class="d-flex">
        <div class="search-box-group mr-8">
          <search-box v-model="searchParams.searchText" holder-text="Search" @on-search="onGetSwapTokos" />

          <selection-box
            v-model="searchParams.searchTarget"
            :options="transactionTargetOptions"
            :is-align-right="true"
            :on-check="onGetSwapTokosByTarget"
            selected-box-class="is-lg search-box__filter"
          />
        </div>
        <button class="btn btn--md btn--primary mr-8" @click="onGetSwapTokos">Search</button>

        <selection-box
          v-model="params.perPage"
          :options="pagerOptions"
          :is-align-right="true"
          :on-check="onGetSwapTokos"
        />
      </div>
    </div>

    <div
      v-if="swapTokos && swapTokos.data.length"
      class="table-wrapper table-wrapper--fluid table-wrapper--expand radius-top-0"
    >
      <table class="table table--striped">
        <thead>
          <tr>
            <th>Email</th>
            <th>Tnx.Hash</th>
            <th>Wallet Address</th>
            <th>Amount<br />TOKO</th>
            <th>Time<br />UTC</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          <router-link
            v-for="swap in swapTokos.data"
            :key="swap.id"
            class="link--clone"
            tag="tr"
            :to="{ name: 'SwapUserTransfer', params: { id: swap.id } }"
          >
            <td :class="{ 'badge badge--triangle-shape tooltip': swap.user.blacklist > 0 }">
              {{ swap.user.email }}
              <span class="tooltip__text" v-if="swap.user.blacklist > 0">{{
                swap.user.blacklist | format_blacklist_type
              }}</span>
            </td>
            <td class="wr-25 clipboard-copy">
              <p class="clipboard-copy__text">
                <span class="text-ellipsis clipboard-copy__text-inner">{{ swap.tnxHash }}</span>
                <copy-clipboard @on-copy="doCopy($event)" :external-link="`/etherscan/${swap.tnxHash}`" />
              </p>
            </td>
            <td class="wr-15 clipboard-copy">
              <p class="clipboard-copy__text">
                <span class="text-ellipsis clipboard-copy__text-inner">{{ swap.userWalletAddress }}</span>
                <copy-clipboard
                  @on-copy="doCopy($event)"
                  :external-link="`/etherscan-address/${swap.userWalletAddress}`"
                />
              </p>
            </td>
            <td>{{ swap.amount | number_with_commas }}</td>
            <td v-html="getMomentLTSFormatted(swap.user_transfer_at_milliseconds)" />
            <td v-html="formatSwapTokoStatus(swap.status)" />
          </router-link>
        </tbody>
      </table>

      <div class="text-center mt-32">
        <p>{{ swapTokos.total | format_transaction_count_text }} in total</p>

        <pagination
          v-if="isNextPage(swapTokos.total)"
          v-model="page"
          :page-count="getPageCount(swapTokos.totalPages, swapTokos.total)"
          :page-range="5"
          :click-handler="clickPagerCallback"
        />
      </div>
    </div>

    <div v-else class="box box--lg box--white radius-top-0 mt-0">
      <no-result />
    </div>

    <loading :open="!isLoaded" />
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { PAGER_OPTIONS, TRANSACTION_SEARCH_TARGET_OPTIONS, SWAP_TOKO_USER_TRANSFER_OPTIONS } from '@/utils/data-sets'
import pagination from '@/mixins/pagination'
import handleAssets from '@/mixins/handle-assets'
import momentltsFormat from '@/mixins/momentlts-format'
import formatSwapTokoStatus from '@/mixins/swap-toko-status'
import toggleLoading from '@/mixins/toggle-loading'
import copyClipboard from '@/mixins/copy-clipboard'
const NoResult = () => import('@/components/NoResult')
const SelectionBox = () => import('@/components/SelectionBox')
const SearchBox = () => import('@/components/SearchBox')
const Pagination = () => import('@/components/Pagination')
const CopyClipboard = () => import('@/components/CopyClipboard')

export default {
  data() {
    return {
      params: {
        perPage: 20,
        address: null,
        tnxHash: null,
        status: 0
      },
      searchParams: {
        searchTarget: 0,
        searchText: ''
      },
      page: 1,
      pagerOptions: PAGER_OPTIONS,
      transactionTargetOptions: TRANSACTION_SEARCH_TARGET_OPTIONS,
      userTransferOptions: SWAP_TOKO_USER_TRANSFER_OPTIONS
    }
  },

  mixins: [pagination, momentltsFormat, handleAssets, formatSwapTokoStatus, toggleLoading, copyClipboard],

  computed: {
    ...mapState('swapToko', ['swapTokos', 'assets']),

    searchTarget() {
      return parseInt(this.searchParams.searchTarget)
    }
  },

  components: {
    NoResult,
    SelectionBox,
    SearchBox,
    Pagination,
    CopyClipboard
  },

  methods: {
    ...mapActions('swapToko', ['getSwapTokos']),

    ...mapMutations('swapToko', ['CLEAR_SWAP_TOKOS_DATA']),

    onGetSwapTokos() {
      this.toggleLoading()
      this.page = 1
      this.getSwapTokos(this.getSwapTokosRequestParams()).finally(() => {
        this.toggleLoading()
      })
    },

    onGetSwapTokosByTarget() {
      if (!this.searchParams.searchText) return
      this.onGetSwapTokos()
    },

    clickPagerCallback() {
      this.toggleLoading()
      this.getSwapTokos(this.getSwapTokosRequestParams()).finally(() => {
        this.toggleLoading()
      })
    },

    getSwapTokosRequestParams() {
      let address, tnxHash
      if (this.searchParams.searchText) {
        tnxHash = this.searchTarget === 0 ? this.searchParams.searchText : this.params.tnxHash
        address = this.searchTarget === 1 ? this.searchParams.searchText : this.params.address
      }

      return {
        page: this.page,
        limit: this.params.perPage,
        status: this.params.status > 0 ? this.params.status : null,
        userWalletAddress: address,
        tnxHash
      }
    }
  },

  created() {
    this['CLEAR_SWAP_TOKOS_DATA']()
    this.getSwapTokos(this.getSwapTokosRequestParams()).finally(() => {
      this.toggleLoading()
    })
  },

  beforeRouteLeave(to, from, next) {
    if (to.name === 'SwapUserTransfer') {
      this.storeAssets('swapToko')
      next()
    } else {
      this.clearAssets('swapToko')
      next()
    }
  }
}
</script>
